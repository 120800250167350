import { createBrowserHistory as createHistory } from "history";
import simpleRestProvider from "ra-data-simple-rest";
import { Admin, fetchUtils, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router";
import MyLayout from "./components/AdminLayout";
import LoginPage from "./pages/Login";
import { ProfileEdit } from "./pages/ProfileEdit";
import Register from "./pages/Register";
import { UserEdit, UserList } from "./pages/Users";
import authProvider from "./providers/authProvider";
import { basePath } from "./providers/env";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import ExtensionIcon from '@mui/icons-material/Extension';
import MapIcon from '@mui/icons-material/Map';
import FeedIcon from '@mui/icons-material/Feed';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import PersonIcon from "@mui/icons-material/Person";
import { GroupEdit, GroupList } from "./pages/Groups";
import { TenantList, TenantEdit, TenantCreate } from "./pages/Tenants";
import { Map } from './pages/Map';
import { SubareaCreate, SubareaEdit, SubareaList } from "./pages/Subareas";
import { HintCreate, HintList } from "./pages/Hints";
import { ArticleList, ArticleShow } from "./pages/Articles";
import { WalkList } from "./pages/Walks";
import HikingIcon from '@mui/icons-material/Hiking';
import { HuntCreate, HuntList } from "./pages/Hunts";
import { lightTheme, darkTheme } from "./theme";
import Activate from "./pages/Activate";
import { TrackerInstructions } from "./pages/TrackerInstructions";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { BlokkenSchema } from "./pages/Blokkenschema";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from "moment";
import 'moment/locale/nl';

// Set the locale to Dutch
moment.locale("nl");

const httpClient = (url: string, options: any = {}) => {
  options.user = {
    authenticated: true,
    token: `Bearer ${localStorage.getItem("token")}`,
  };
  if (url.includes("/users/") && options.method === "PUT") {
    // We use PATCH for update on the backend for users, since PATCH is selective PUT, this change should be fine
    options.method = "PATCH";
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(`${basePath}/api/v1`, httpClient);


const App = () => {
  return (
    <Admin
      disableTelemetry
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginPage}
      history={createHistory()}
      layout={MyLayout}
      theme={lightTheme}
      darkTheme={darkTheme}
    >
      <CustomRoutes>
        <Route path="/my-profile" element={<ProfileEdit />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/activate" element={<Activate />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/register" element={<Register />} />
      </CustomRoutes>
      {(permissions) => [
        <Resource
          name="kaart"
          options={{ label: "Kaart" }}
          list={Map}
          icon={MapIcon}
        />,
        <Resource
          name="groups"
          options={{ label: "Scoutinggroepen" }}
          list={GroupList}
          edit={GroupEdit}
          icon={HouseSidingIcon}
        />,
        <Resource
          name="articles"
          options={{ label: "Nieuws" }}
          list={ArticleList}
          show={ArticleShow}
          icon={FeedIcon}
        />,
        <Resource
          name="hints"
          options={{ label: "Hints" }}
          list={HintList}
          create={HintCreate}
          icon={ExtensionIcon}
        />,
        <Resource
          name="hunts"
          options={{ label: "Hunts" }}
          list={HuntList}
          create={HuntCreate}
          icon={CrisisAlertIcon}
        />,
        <Resource
          name="walks"
          options={{ label: "Dataanalyse" }}
          list={WalkList}
          icon={HikingIcon}
        />,
        <Resource
          name="tracker"
          options={{ label: "Tracker instellingen" }}
          list={TrackerInstructions}
          icon={GpsFixedIcon}
        />,
        <Resource
          name="blokkenschema"
          options={{ label: "Planning" }}
          list={BlokkenSchema}
          icon={CalendarMonthIcon}
        />,
        permissions.is_superuser === true ? (
          <Resource
            options={{ label: "Omgevingen" }}
            name="tenants"
            list={TenantList}
            edit={TenantEdit}
            create={TenantCreate}
            icon={PersonIcon}
          />
        ) : null,
        permissions.is_superuser === true ? (
          <Resource
            options={{ label: "Gebruikers" }}
            name="users"
            list={UserList}
            edit={UserEdit}
            icon={PersonIcon}
          />
        ) : null,
        permissions.is_superuser === true ? (
          <Resource
            name="subareas"
            options={{ label: "Deelgebieden" }}
            list={SubareaList}
            create={SubareaCreate}
            edit={SubareaEdit}
            icon={MapIcon}
          />
        ) : null,
      ]}
    </Admin >
  );
};

export default App;
