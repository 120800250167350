import React from 'react';
import moment from 'moment';

interface Props {
    source: string;
    record?: Record<string, any>;
}
const DateTimeField = ({ source, record = {} }: Props) => {
    return <span>{moment(record[source]).calendar()}</span>;
};

export default DateTimeField;
