import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { useGetList } from "react-admin"
import DateTimeField from './DateTimeField';
import { useMediaQuery } from '@mui/material';

const statusColors = {
    "red": "error",
    "orange": "warning",
    "green": "success",
}

interface PropTypes {
    setFilter: any,
    filter: number,
}

export function SubareaStatus(props: PropTypes) {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const { data, isLoading } = useGetList('subareas/latest_status', {
        pagination: { page: 1, perPage: 100 },
        order: { field: 'name', order: 'ASC' },
    })

    if (isLoading) { return <Skeleton height={60} />; }

    return (
        <Grid container spacing={0}>
            {data.map(subarea => <Grid item md={2} xs={2} key={`status-${subarea.id}`}>
                <Button variant="contained" color={statusColors[subarea.subarea_statuses.status]} onClick={() => props.filter === subarea.id ? props.setFilter(0) : props.setFilter(subarea.id)} fullWidth>
                    <Grid container spacing={0}>
                        {isSmall ? (
                            <Grid item xs={12}>
                                {subarea.name.substring(0, 1)}
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                {subarea.name}
                            </Grid>
                        )}
                        {!isSmall ? <Grid item xs={12} style={{ fontSize: '0.6rem' }}>
                            <DateTimeField source="created" record={subarea.subarea_statuses} />
                        </Grid> : null}
                    </Grid>
                </Button>
            </Grid>)
            }
        </Grid >
    )
}
