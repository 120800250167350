import {
    useGetList,
    EditButton,
} from "react-admin";
import { Hint } from "../generated";
import { Marker } from "./Marker";
import { Typography, CardContent, CardActions, List, ListItem, ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExtensionIcon from '@mui/icons-material/Extension';
import DirectionsIcon from '@mui/icons-material/Directions';
import DateTimeField from "./DateTimeField";
import { getTodayFilter } from "../utils/dates";

interface Props {
    setSidebar: any,
    subareaFilter: number | undefined,
}

export const Hints = (props: Props) => {
    const filter: any = getTodayFilter();

    if (props.subareaFilter) {
        filter["subarea_id"] = props.subareaFilter;
    }

    const hints = useGetList(
        'hints',
        {
            pagination: { page: 1, perPage: 100 },
            filter: filter,
        }
    ).data;

    return (<>
        {hints && hints.map((hint: Hint, index: number) => (
            <Marker
                position={{ latitude: hint.latitude, longitude: hint.longitude }}
                key={"hint-" + index}
                type={hint.type?.toLocaleLowerCase()}
                setSidebar={props.setSidebar}
            >
                <>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Waypoint {hint.type?.toLocaleLowerCase()} {hint.subarea.name}
                        </Typography>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton href={"https://www.google.com/maps/dir/?api=1&destination=" + hint.latitude + "%2C" + hint.longitude} target="_blank">
                                    <ListItemIcon>
                                        <DirectionsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={hint.address} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <AccessTimeIcon />
                                    </ListItemIcon>
                                    <DateTimeField source="created" record={hint} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton href={`/hints/${hint.id}`}>
                                    <ListItemIcon>
                                        <ExtensionIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<>{hint.rdx} {hint.rdy}</>} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                    <CardActions>
                        <EditButton resource="hints" record={hint} label="Bewerk" />
                    </CardActions>
                </>
            </Marker >
        ))}
    </>)

}
