import {
    useGetList,
} from "react-admin";
import Voronoi from '../utils/voronoi';
import { Polygon } from '@react-google-maps/api';

interface PropTypes {
    subareaFilter: number | undefined,
}

const VoronoiMap = (props: PropTypes) => {
    const groups = useGetList(
        'groups',
        {
            pagination: { page: 1, perPage: 100 },
            filter: props.subareaFilter ? { subarea_id: props.subareaFilter } : undefined,
        }
    ).data;

    const voronoi = new Voronoi();

    const bbox = { xl: 51.4, xr: 53.0, yt: 5.1, yb: 6.7 }; // xl is x-left, xr is x-right, yt is y-top, and yb is y-bottom

    if (groups) {
        const sites = groups.map((group: any) => {
            return { x: group.latitude, y: group.longitude, subarea: group.subarea ? group.subarea : { id: 0, color: "#000000" } };
        });

        const diagram = voronoi.compute(sites, bbox);

        const polygons = diagram.cells.map((cell: any, i: number) => {
            let opacity = 0.1;
            const options = {
                fillColor: `${cell.site.subarea.color}`,
                fillOpacity: cell.site.subarea.id === 0 ? 0 : opacity,
                strokeWeight: 0,
            };
            let path: any = [];
            cell.halfedges.map((edge: any) => {
                path.push({ lat: edge.getStartpoint().x, lng: edge.getStartpoint().y });
                path.push({ lat: edge.getEndpoint().x, lng: edge.getEndpoint().y });
                return null;
            });

            return (<Polygon key={i} path={path} options={options} />);
        });
        return <>{polygons}</>;
    } else {
        return <></>;
    }
}


export default VoronoiMap;