import {
    useGetList,
} from "react-admin";
import { Group } from "../generated";
import { Circle } from "@react-google-maps/api";

interface PropTypes {
    subareaFilter: number | undefined,
}

export const GroupCircles = (props: PropTypes) => {

    const groups = useGetList(
        'groups',
        {
            pagination: { page: 1, perPage: 100 },
            filter: props.subareaFilter ? { subarea_id: props.subareaFilter } : undefined,
        }
    ).data;

    return (<>
        {groups && groups.map((group: Group, index: number) => (
            <Circle
                center={{ lat: group.latitude, lng: group.longitude }}
                radius={450}
                key={"circle-" + index}
                options={{
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.3,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.2,
                }} />

        ))}
    </>)

}