import { Polyline } from "@react-google-maps/api";

interface Props {
    path: google.maps.LatLngLiteral,
    pathValues: number[],
    options: {
        innerColor: string,
        outerColor: string,
        zIndex: number,
    },
    setMarkerState: any,
}

export const HoverablePolyline = (props: Props) => {
    const visitedPath = props.path;
    const visitedTimes = props.pathValues;
    return <>
        <Polyline options={{
            strokeColor: props.options.outerColor,
            strokeWeight: 9,
            zIndex: props.options.zIndex - 1,
        }} path={visitedPath}
            onMouseOut={() => {
                props.setMarkerState({ latitude: 0.0, longitude: 0.0, time: 0, speed: 0 });
            }}
            key={"prediction"} />, <Polyline options={{
                strokeColor: props.options.innerColor,
                strokeWeight: 5,
                zIndex: props.options.zIndex,
            }} path={visitedPath} key={"prediction-overlay"}

                onMouseOut={() => {
                    props.setMarkerState({ latitude: 0.0, longitude: 0.0, time: 0 });
                }}

                onMouseOver={(e) => {
                    if (e.latLng && visitedPath && visitedTimes) {
                        // Find closest point in visitedPath using google.maps.geometry.spherical.computeDistanceBetween
                        let closestPoint = visitedPath[0];
                        let closestDistance = google.maps.geometry.spherical.computeDistanceBetween(closestPoint, e.latLng);
                        for (let i = 1; i < visitedPath.length; i++) {
                            const point = visitedPath[i];
                            const distance = google.maps.geometry.spherical.computeDistanceBetween(point, e.latLng);
                            if (distance < closestDistance) {
                                closestPoint = point;
                                closestDistance = distance;
                            }
                        }

                        const latlng = { latitude: e.latLng.lat(), longitude: e.latLng.lng(), time: visitedTimes[visitedPath.indexOf(closestPoint)] };
                        props.setMarkerState(latlng);
                    }
                }} /></>;
}
