import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import ExtensionIcon from '@mui/icons-material/Extension';
import { Link } from 'react-router-dom';
import { useCreatePath } from 'react-admin';


const actions = [
    { icon: <CrisisAlertIcon />, name: 'Hunt', resource: 'hunts', type: 'create' },
    { icon: <ExtensionIcon />, name: 'Hint', resource: 'hints', type: 'create' },
];

export default function SpeedDialButtons() {
    const createPath = useCreatePath();

    return (
        <Box sx={{ transform: 'translateZ(100px)', flexGrow: 1 }}>
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', bottom: 16, left: 16 }}
                icon={<SpeedDialIcon />}
                direction="right"
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={<Link
                            to={createPath({ resource: action.resource, type: action.type })}
                            style={{
                                color: 'inherit',
                                textDecoration: 'none',
                                marginBottom: '-0.5em',
                            }}
                        >{action.icon}
                        </Link>}
                        tooltipTitle={action.name}
                    />
                ))}
            </SpeedDial>
        </Box >
    );
}