import { type CSSProperties } from 'react';
import { GoogleMap as GoogleMapApi } from "@react-google-maps/api";
import { SearchBoxAndSearchResults } from './SearchBoxAndSearchResults';


const containerStyle: CSSProperties = {
    width: '100%',
    margin: '0px',
    height: 'calc(100vh - 48px - 48px)'
};

const center = {
    lat: 52.1,
    lng: 6
};

const zoom = 10;

interface Props {
    center?: { lat: number, lng: number },
    zoom?: number,
    containerStyle?: CSSProperties,
    children?: any,
    searchBox?: boolean,
    setSidebar?: any,
}

export const GoogleMap = (props: Props) => {

    return <GoogleMapApi
        mapContainerStyle={{ ...containerStyle, ...props.containerStyle }}
        center={props.center || center}
        zoom={props.zoom || zoom}
    >
        {props.searchBox && <SearchBoxAndSearchResults setSidebar={props.setSidebar} />}
        {props.children}
    </GoogleMapApi >;
}
