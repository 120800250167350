import { useGetList } from "react-admin"
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import { indigo } from '@mui/material/colors';
import moment from 'moment';
import { getTodayFilter } from "../utils/dates";

const WalkingGraph = (props: any) => {
  const data = props.walk.map((walk: any) => {
    return {
      time: Math.floor(new Date(walk.created).getTime() / 1000)
      , snelheid: Math.min((walk.distance / walk.time) * 3.6, 10)
    }
  })

  return (
    <LineChart width={730} height={250} data={data}

      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="time"
        type='number'
        domain={['auto', 'auto']}
        tickFormatter={(unixTime) => moment(unixTime).format('HH:mm DD-MM')}

      >
        <Label value="Tijd" offset={0} position="bottom" />
      </XAxis>
      <YAxis label={{ value: 'Snelheid', angle: -90, position: 'insideLeft' }}
        domain={[-1, 10]}
        tickSize={0}
      />
      <Tooltip
        labelFormatter={(value) => 'Tijdstip : ' + moment(value).format('HH:mm DD-MM')}
        formatter={(value, name, props) => {
          if ((value as number) === 10) return ['> 10 km/h', 'Snelheid (km/h)']
          return ['' + (value as number).toFixed(2) + ' km/h', 'Snelheid']
        }}

      />
      <Legend />
      <Line type="monotone" dataKey="snelheid" stroke={indigo[800]} />
    </LineChart>
  )
}

const StatusCard = (props: any) => {
  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {props.subarea.name}
          </Typography>
          <WalkingGraph walk={props.walk} />
        </CardContent>
      </Card>
    </Box>
  )
}

export const WalkList = (props: any) => {
  const todayFilter = getTodayFilter();

  const { data, isLoading } = useGetList('walks/group_by_subarea', {
    filter: todayFilter
  })

  if (isLoading) { return <Skeleton height={60} />; }

  return (
    <Grid container spacing={4}>
      {data.map((walk: any) => (<Grid item lg={6} md={12} key={'walk-' + walk.id}>
        <StatusCard subarea={walk.subarea} walk={walk.walks} />
      </Grid>))
      }
    </Grid>
  )
}