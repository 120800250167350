import { Marker, StandaloneSearchBox } from "@react-google-maps/api"
import { CSSProperties, useState } from "react";
import { Typography, CardContent, List, ListItem, ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import DirectionsIcon from '@mui/icons-material/Directions';

interface Props {
    setSidebar: any,
}


const inputStyle: CSSProperties = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    top: '10px',
    left: '40%',
}


export const SearchBoxAndSearchResults = (props: Props) => {
    const [searchBox, setSearchBox] = useState<any>(null);
    const [places, setPlaces] = useState([]);

    const onPlacesChanged = () => {
        if (searchBox) {
            setPlaces(searchBox.getPlaces());
        }
    };
    const onSBLoad = (ref: any) => {
        setSearchBox(ref);
    };

    return (
        <>
            <StandaloneSearchBox onPlacesChanged={onPlacesChanged}
                onLoad={onSBLoad}>
                <input
                    type='text'
                    placeholder='Zoeken'
                    style={inputStyle}
                />
            </StandaloneSearchBox>
            {
                places.map((place: any) => (
                    <Marker key={place.place_id} position={{ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }}
                        onClick={() => {
                            props.setSidebar(
                                <>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            {place.name}
                                        </Typography>
                                        <List>
                                            <ListItem disablePadding>
                                                <ListItemButton href={"https://www.google.com/maps/dir/?api=1&destination=" + place.geometry.location.lat() + "%2C" + place.geometry.location.lng()} target="_blank">
                                                    <ListItemIcon>
                                                        <DirectionsIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={place.formatted_address} />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </>
                            )
                        }}
                    />
                ))
            }
        </>
    )
}