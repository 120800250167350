import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { useState } from 'react';
import Button from '@mui/material/Button';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const TrackerInstructions = (props: any) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    return (
        <>
            <Container>
                <Paper>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="devices">
                            <Tab label="Android" {...a11yProps(0)} />
                            <Tab label="iOS" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Typography variant="body1" gutterBottom component="div">
                            Nieuw: de Jotihunt Tracker app voor Android! Download de app via de App Store.
                            Met de Jotihunt Tracker app kun je de locatie van de Jotihunt auto's volgen.

                            <br />
                            <Button variant="contained" href="https://play.google.com/store/apps/details?id=org.owntracks.android&hl=en_US&pli=1" target="_blank">
                                <AndroidIcon /> Download
                            </Button>

                            <Typography variant="body1" gutterBottom component="div">
                                <ol>
                                    <li>Open de app</li>
                                    <li>Klik op de hamburgerknop linksbovenin en vervolgens op "Preferences"</li>
                                    <li>Klik op Connection/Verbinding</li>
                                    <li>Selecteer bij Mode HTTP</li>
                                    <li>Voer bij Host https://jotihunt.scouting-ijsselgroep.nl/api/v1/cars in.</li>
                                    <li>Voer bij Identification de volgende waardes in:</li>
                                    <ul>
                                        <li>Username: leeg laten</li>
                                        <li>Password: leeg laten</li>
                                        <li>Device ID: jouw naam</li>
                                        <li>Tracker ID: 2 willekeurige letters/cijfers</li>
                                    </ul>
                                    <li>Klik op accept</li>
                                    <li>
                                        Zorg ervoor dat de app op de achtergrond blijft draaien en dat de locatieinstellingen juist staan.
                                    </li>
                                    <li>
                                        Zet battery whitelisting aan. Druk op de (i) rechtsbovenin. Dit brengt je naar het status scherm. Druk op de 3 puntjes rechtsbovenin en selecteer "Battery optimization". Zoek Owntracks op en zet het op "don't optimize".
                                    </li>
                                    <li>
                                        Om de status te checken:
                                        Druk op de (i) rechtsbovenin. Dit brengt je naar het status scherm. Om zeker te zijn dat het werkt, check   of het response nummer bij "endpoint state message" begint met een 2. Zo nee, check je instellingen.
                                    </li>
                                </ol>
                            </Typography>
                        </Typography>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Typography variant="body1" gutterBottom component="div">
                            Nieuw: de Jotihunt Tracker app voor iOS! Download de app via de App Store.
                            Met de Jotihunt Tracker app kun je de locatie van de Jotihunt auto's volgen.

                            <br />
                            <Button variant="contained" href="https://apps.apple.com/us/app/owntracks/id692424691" target="_blank">
                                <AppleIcon /> Download
                            </Button>
                        </Typography>
                        <Typography variant="h6" gutterBottom component="div">
                            Instellingen
                        </Typography>
                        <Typography variant="body1" gutterBottom component="div">
                            <ol>
                                <li>Open de app</li>
                                <li>Klik op de i linksbovenin en vervolgens op "Instellingen"</li>
                                <li>Selecteer HTTP en voer de volgende waardes in:</li>
                                <ul>
                                    <li>TrackerID: 2 willekeurige letters</li>
                                    <li>ApparaatID: jouw voornaam</li>
                                    <li>URL: https://jotihunt.scouting-ijsselgroep.nl/api/v1/cars</li>
                                    <li>De van de instellingen laat je leeg, authenticatie laat je uit staan</li>
                                </ul>
                                <li>
                                    Klik op "vorige". Als het goed is komen er bij "toestand" geen foutmeldingen te staan.
                                </li>
                                <li>
                                    Zorg ervoor dat de app op de achtergrond blijft draaien en dat de locatieinstellingen juist staan.
                                    <ul>
                                        <li>
                                            De instellingen staan juist als bij de i de toestand op "inactief" en verbinden staat.
                                        </li>
                                    </ul>
                                </li>
                                <ul>
                                    <li>Instellingen (van iOS zelf) &gt; Owntracks &gt; Locatie &gt; "Altijd"</li>
                                </ul>
                            </ol>
                        </Typography>
                    </CustomTabPanel>
                </Paper>
            </Container>
        </>
    )
};