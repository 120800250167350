import { useGetList } from "react-admin";
import { HoverablePolyline } from "./HoverablePath";
import { useState } from "react";
import { Marker } from "./Marker";
import { Typography, CardContent, List, ListItem, ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import DirectionsIcon from '@mui/icons-material/Directions';
import SpeedIcon from '@mui/icons-material/Speed';
import { InfoWindow, Polyline } from "@react-google-maps/api";
import DateTimeField from "./DateTimeField";
import { getTodayFilter } from "../utils/dates";

interface Props {
    setSidebar: any,
    subareaFilter: number | undefined,
}

export const FoxTeams = (props: Props) => {
    // Only show predictions from today
    const todayFilter: any = getTodayFilter();



    const walks = useGetList(
        'walks',
        {
            filter: todayFilter,
        }
    ).data;

    // Only show predictions that are less than 20km away
    const filter: any = getTodayFilter();
    filter["distance"] = { "$lt": 20 * 1000 }

    const predictions = useGetList(
        'predictions',
        {
            sort: { field: 'created', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
            filter: filter,
        }
    ).data;

    const [markerState, setMarkerState] = useState({ latitude: 0.0, longitude: 0.0, time: 0 });

    return (<>
        {markerState && markerState.latitude && markerState.longitude && [<Polyline
            path={[{ lat: markerState.latitude, lng: markerState.longitude }, { lat: markerState.latitude, lng: markerState.longitude }]}
            options={{
                strokeColor: '#F5F5F5',
                clickable: false,

                strokeWeight: 12,
                zIndex: 10,
            }}
            key={0}
            onMouseOut={() => { setMarkerState({ latitude: 0.0, longitude: 0.0, time: 0 }) }}
        />, <Polyline

            path={[{ lat: markerState.latitude, lng: markerState.longitude }, { lat: markerState.latitude, lng: markerState.longitude }]}
            options={{
                clickable: false,
                strokeColor: '#BDBDBD',
                strokeWeight: 15,
                zIndex: 9,
            }}
            key={1}
            onMouseOut={() => { setMarkerState({ latitude: 0.0, longitude: 0.0, time: 0 }) }}
        />, <InfoWindow
            key={2}
            position={{ lat: markerState.latitude, lng: markerState.longitude }}
        ><div><Typography variant="button" component="div">
            Verwachte positie
        </Typography>
                <Typography variant="body"><DateTimeField source="time" record={markerState} /></Typography>
            </div></InfoWindow>]}


        {walks && walks.map((walk: any, index: number) => {
            if (props.subareaFilter !== 0 && walk.hint.subarea_id !== props.subareaFilter) {
                return null;
            }
            return walk.path.map((pathMap: string, index: number) => {
                return <HoverablePolyline path={google.maps.geometry.encoding.decodePath(pathMap)} setMarkerState={setMarkerState} key={"walk-hover-" + index} options={{ innerColor: '#43A047', outerColor: '#2E7D32', zIndex: 9 }} />
            })
        })}
        {predictions && predictions.map((prediction: any, index: number) => {
            const wholepath = prediction.path.map(google.maps.geometry.encoding.decodePath).flat(1).map((point: any) => { return { lat: point.lat(), lng: point.lng() } });
            const predictedSpeed = Math.min(7 / 3.6, prediction.distance / prediction.time);

            if (props.subareaFilter !== 0 && prediction.group.subarea_id !== props.subareaFilter) {
                return [];
            }

            if (wholepath.length > 0) {
                // Traverse over wholepath, with a walking distance of 1.7 meters per second, until we reach the walking time of two hours
                let lastPoint = wholepath[0];
                let lastTime = new Date(prediction.created).getTime();

                let currentTime = new Date().getTime();
                let timeDiff = 0;

                let visitedTimes = [];
                let visitedPath = wholepath;
                let futurePath = [];
                let futureTimes = [];

                for (let i = 1; i < wholepath.length; i++) {
                    const point = wholepath[i];
                    const distanceDiff = google.maps.geometry.spherical.computeDistanceBetween(lastPoint, point);
                    timeDiff += (distanceDiff / predictedSpeed) * 1000;
                    visitedTimes.push(lastTime + timeDiff);
                    if (currentTime < lastTime + timeDiff) {
                        visitedPath = wholepath.slice(0, i);
                        futurePath = wholepath.slice(i - 1);
                        break;
                    }
                    lastPoint = point;
                }

                const currentPoint = lastPoint;

                // Calculate rest of futureTimes
                for (let i = 0; i < futurePath.length; i++) {
                    const point = futurePath[i];
                    const distanceDiff = google.maps.geometry.spherical.computeDistanceBetween(lastPoint, point);
                    timeDiff += (distanceDiff / predictedSpeed) * 1000;
                    futureTimes.push(lastTime + timeDiff);
                    lastPoint = point;
                }

                return [<Marker position={{ latitude: currentPoint.lat, longitude: currentPoint.lng }} key={"prediction-marker-" + index} type="prediction" setSidebar={props.setSidebar}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Vossenteam {prediction.group.subarea.name}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            Verwachte positie
                        </Typography>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <DirectionsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={prediction.group.name} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <SpeedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={(prediction.distance / prediction.time * 3.6).toFixed(1) + ' km/h'} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>

                </Marker>,
                <HoverablePolyline path={visitedPath} pathValues={visitedTimes} key={"visited-path-" + index} setMarkerState={setMarkerState} options={{ innerColor: '#00b0ff', outerColor: '#1967d2', zIndex: 4 }} />,
                <HoverablePolyline path={futurePath} pathValues={futureTimes} key={"future-path-" + index} setMarkerState={setMarkerState} options={{ innerColor: '#bbbdbf', outerColor: '#929497', zIndex: 2 }} />,

                ]
            }
            return null;
        })}
    </>)
}